import React from 'react'
import Navbar from '../component/Navbar'
import AboutSection from '../component/About'
import Footer from '../component/Footer'

function About() {
  return (
    <div>
      <Navbar/>
      <AboutSection/>
      <Footer/>
    </div>
  )
}

export default About