import React from 'react'
import AdminHeader from '../component/AdminHeader'
import Dashboard from '../component/Dashboard'

function AdminDashboard() {
  return (
    <div><AdminHeader/>
    <Dashboard/>
    </div>
  )
}

export default AdminDashboard