import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from 'firebase/firestore';
import { Auth, getAuth } from 'firebase/auth';

let isFirebaseInitialized = false;

const FirebaseApp = {
  apiKey: "AIzaSyD8-Rgf52B7VwQM7oDAVNPeGSvMeEiHZJY",
  authDomain: "cadence-web-app.firebaseapp.com",
  projectId: "cadence-web-app",
  storageBucket: "cadence-web-app.appspot.com",
  messagingSenderId: "449640482320",
  appId: "1:449640482320:web:f4769e74cf105e4f722c95"
};

let database: Firestore; // Explicitly define as Firestore
let auth: Auth; // Explicitly define as Auth



const initializeFirebase = () => {
  if (!isFirebaseInitialized) {
    const store = initializeApp(FirebaseApp);
    database = getFirestore(store);
    auth = getAuth(store);
    isFirebaseInitialized = true;
  }
};


initializeFirebase();

export { auth, database };
