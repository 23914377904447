import React from 'react'
import Conttact from '../component/Conttact'

function Contact() {
  return (
    <div>
      <Conttact/>
    </div>
  )
}

export default Contact