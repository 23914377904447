import { FaCarAlt, FaRegCalendarAlt } from "react-icons/fa";
function Curbside() {
  return (
    <div className="py-16 bg-gray-200">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center py-6 px-10">
        <div className="">
          <div className="font-bold text-blue-300 pb-1">ADDRESS</div>
          <div className="pb-1 text-sm">539 E 56th Ave Denver, CO 80216</div>
          <div className="font-bold text-blue-300 pt-1">
            PHONE: +17202952693
          </div>
        </div>

        <div className="flex flex-col items-center">
          <div
            className="font-bold text-blue-300 pb-1"
            style={{ fontSize: "2rem" }}
          >
            <FaCarAlt />
          </div>
          <div className="font-bold text-blue-300 pt-1 text-sm">
            CURBSIDE AVAILABLE
          </div>
        </div>

        <div className=" flex flex-col items-center">
          <div
            className="font-bold text-blue-300 pb-1"
            style={{ fontSize: "2rem" }}
          >
            <FaRegCalendarAlt />
          </div>
          <div className="font-bold text-blue-300 pt-1 text-sm">
            SHOP BY APPOINTMENT
          </div>
        </div>
      </div>

      <div className="text-center py-8 text-[2rem]">
      Testimonials
      </div>

      <div className="max-w-[1000px] m-auto flex justify-between items-center flex-col md:flex-row">
        <div className="max-w-sm m-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <img
            className="rounded-t-lg h-[40vh] w-full"
            src="/image/t2.jpeg"
            alt=""
          
          />
          <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Sarah Mcnick
            </h5>

            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            I was happy to get my dream instrument for a wonderful price.
            </p>
          </div>
        </div>

        <div className="max-w-sm m-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <img
            className="rounded-t-lg h-[40vh] w-full"
            src="/image/t1.jpeg"
            alt=""
          
          />
          <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            John Stuggart
            </h5>

            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            I had a wonderful experience renting a French Horn for an out of state Gig.
            </p>
          </div>
        </div>

        <div className="max-w-sm m-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <img
            className="rounded-t-lg h-[40vh] w-full"
            src="/image/t3.webp"
            alt=""
          
          />
          <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Cindy James 
            </h5>

            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            I was impressed with the packaging and delivery. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Curbside;
